import { omit } from 'lodash'
import rs from 'randomstring'
import dayjs from 'dayjs'
import minMax from 'dayjs/plugin/minMax'
import { dateDiffDays } from '@/app/system/helper'
import {
  chain,
  countBy,
  every,
  filter,
  findIndex,
  isEmpty,
  map,
  reduce,
  without
} from 'lodash'
import { DATE_FORMAT } from '@/app/constants'

dayjs.extend(minMax)

export class RoomStay {
  constructor({
    adults = 2,
    children = 0,
    infants = 0,
    order = null,
    completed = false
  } = {}) {
    this.adults = adults
    this.children = children
    this.infants = infants
    this.order = order
    this.completed = completed
  }

  _calculateTaxTotal(sourceArray, taxKey = 'taxAmount') {
    if (this.order === null) return 0;

    if (Array.isArray(sourceArray)) {
      if (sourceArray.length === 0) return 0;

      // If the first item has a 'taxes' property, we're dealing with services
      if (sourceArray[0].taxes) {
        // Handle services with taxes arrays
        return reduce(
          sourceArray,
          (total, service) => total + reduce(
            service.taxes,
            (subTotal, tax) => subTotal + tax[taxKey],
            0
          ),
          0
        );
      } else {
        return reduce(
          sourceArray,
          (total, tax) => total + tax[taxKey],
          0
        );
      }
    }

    return 0;
  }

  get isBooked() {
    return this.order !== null && this.completed
  }

  get totalServicesPrice() {
    return this.order !== null
      ? reduce(
        this.order.services,
        (totalServicesPrice, { totalPrice }) =>
          totalServicesPrice + totalPrice,
        0
      )
      : 0
  }

  get hotelTotalServicesPrice() {
    return this.order !== null
      ? reduce(
        this.order.services,
        (hotelTotalServicesPrice, { hotelTotalPrice, hotelTotalDiscount }) =>
          hotelTotalServicesPrice + (hotelTotalPrice - hotelTotalDiscount),
        0
      )
      : 0
  }

  get totalPrice() {
    if (this.order === null) {
      return 0
    }
    return this.order.totalPrice + this.totalServicesPrice
  }

  get hotelTotalPrice() {
    if (this.order === null) {
      return 0
    }
    return this.order.hotelTotalPrice + this.hotelTotalServicesPrice
  }

  get totalDiscountedPrice() {
    if (this.order === null) {
      return 0
    }
    return this.order.totalPrice - this.order.totalDiscount
  }

  get hotelTotalDiscountedPrice() {
    if (this.order === null) {
      return 0
    }
    return this.order.hotelTotalPrice - this.order.hotelTotalDiscount
  }

  get taxesTotalAmount() {
    return this._calculateTaxTotal(this.order?.taxes, 'taxAmount');
  }

  get taxesHotelCurrencyTotalAmount() {
    return this._calculateTaxTotal(this.order?.taxes, 'taxHotelAmount');
  }

  get taxesTotalServicesAmount() {
    return this._calculateTaxTotal(this.order?.services, 'taxAmount');
  }

  get taxesHotelCurrencyTotalServicesAmount() {
    return this._calculateTaxTotal(this.order?.services, 'taxHotelAmount');
  }
}

export class BookingRequest {
  constructor({
    checkIn = '',
    checkOut = '',
    promoCodeInput = '',
    sessionId = rs.generate(16),
    guestInfo = {},
    paymentInfo = null,
    payableAmount = 0
  } = {}) {
    this.checkIn = checkIn
    this.checkOut = checkOut
    this.promoCodeInput = promoCodeInput
    this.roomStays = [new RoomStay()]
    this.sessionId = sessionId
    this.guestInfo = guestInfo
    this.paymentInfo = paymentInfo
    this.payableAmount = payableAmount
  }

  get nbNights() {
    const checkOutDate = dayjs(this.checkOut, DATE_FORMAT)
    return checkOutDate.isValid
      ? dateDiffDays(checkOutDate, dayjs(this.checkIn, DATE_FORMAT))
      : 0
  }

  get rooms() {
    return this.roomStays.length
  }

  get nbBookedRooms() {
    return filter(this.roomStays, ({ isBooked }) => isBooked === true).length
  }

  get totalPrice() {
    return reduce(
      this.roomStays,
      (totalPrice, { totalDiscountedPrice, taxesTotalAmount, taxesTotalServicesAmount }) =>
        totalPrice + totalDiscountedPrice + taxesTotalAmount + taxesTotalServicesAmount,
      0
    )
  }

  get hotelTotalPrice() {
    return reduce(
      this.roomStays,
      (
        accumulator,
        { hotelTotalDiscountedPrice, hotelTotalServicesPrice, taxesHotelCurrencyTotalAmount, taxesHotelCurrencyTotalServicesAmount }
      ) =>
        accumulator +
        hotelTotalDiscountedPrice +
        hotelTotalServicesPrice +
        taxesHotelCurrencyTotalAmount +
        taxesHotelCurrencyTotalServicesAmount,
      0
    )
  }
}

/**
 * Creates a new instance of "BookingRequest" based on the given data.
 * We have to replace "bookingRequest" in the state on every update of its fields because we need the Vuex
 * watcher calls "bookingRequest"'s update handler.
 *
 * @param {BookingRequest | object} bookingRequestData1
 * @param {BookingRequest | object | undefined} bookingRequestData2
 * @return BookingRequest
 */
export const createBookingRequest = (
  bookingRequestData1,
  bookingRequestData2 = null
) => {
  bookingRequestData2 = !isEmpty(bookingRequestData2)
    ? bookingRequestData2
    : bookingRequestData1
  const bookingRequestNew = new BookingRequest({
    ...bookingRequestData1,
    ...bookingRequestData2
  })
  const roomStays = !isEmpty(bookingRequestData2?.roomStays)
    ? bookingRequestData2.roomStays
    : bookingRequestData1.roomStays

  map(
    roomStays,
    ({ adults, children, infants, order, completed }, roomStayIndex) => {
      bookingRequestNew.roomStays[roomStayIndex] = new RoomStay({
        adults,
        children,
        infants,
        order,
        completed
      })
    }
  )

  return bookingRequestNew
}

export default {
  state: {
    bookingRequest: new BookingRequest(),
    bookingCompleted: false
  },
  getters: {
    bookingRequest: ({ bookingRequest }) => bookingRequest,
    currentRoom: ({ bookingRequest }) => {
      const index = findIndex(
        bookingRequest.roomStays,
        ({ isBooked }) => isBooked === false
      )

      return index < 0 ? bookingRequest.rooms - 1 : index
    },
    currentRoomStayGuests: (state, { bookingRequest, currentRoom }) =>
      bookingRequest.roomStays[currentRoom],
    isAllRoomStaysBooked: (state, { bookingRequest }) =>
      every(bookingRequest.roomStays, (roomStay) => roomStay.isBooked),
    nbUnbookedRoomStays: (state, { bookingRequest }) =>
      reduce(
        bookingRequest.roomStays,
        (nbUnbookedRoomStays, { isBooked }) =>
          isBooked === false ? ++nbUnbookedRoomStays : nbUnbookedRoomStays,
        0
      ),
    bookingRequestToBeSent: (
      _,
      { bookingRequest, roomStayOrdersToBeSent, channelParametersByChannel }
    ) => {
      const { sessionId, guestInfo, paymentInfo } = bookingRequest
      const channelParameters = channelParametersByChannel
      const bookingRequestToBeSent = {
        roomStays: roomStayOrdersToBeSent,
        sessionId,
        guestInfo
      }

      if (paymentInfo) {
        bookingRequestToBeSent.paymentInfo = paymentInfo
      }

      if (channelParameters) {
        bookingRequestToBeSent.channelParameters = channelParameters
      }
      return bookingRequestToBeSent
    },
    roomStayOrders: (state, { bookingRequest }) =>
      chain(bookingRequest.roomStays)
        .filter(({ order }) => !!order)
        .map(({ order, hotelTotalDiscountedPrice }) => ({
          ...order,
          totalPrice: order.totalPrice,
          hotelTotalPrice: hotelTotalDiscountedPrice
        }))
        .value(),
    roomStaysDates:
      (state, { bookingRequest }) =>
        (date = 'startDate') =>
          bookingRequest.roomStays?.map((roomStay) =>
            dayjs(roomStay.order?.[date], DATE_FORMAT)
          ),
    roomStaysNearestDate: (state, { roomStaysDates }) =>
      dayjs.min(roomStaysDates('startDate')),
    roomStaysFarthestDate: (state, { roomStaysDates }) =>
      dayjs.max(roomStaysDates('endDate')),
    numDaysBeforeCheckIn: (state, { roomStaysNearestDate }) =>
      dateDiffDays(dayjs(), roomStaysNearestDate),
    roomStayOrdersToBeSent: (state, { bookingRequest }) => {
      return bookingRequest.roomStays
        .filter(({ order }) => !!order)
        .map(({ order }) => omit(order, ['rate', 'room']))
    },
    selectedRoomTypes: (state, { roomStayOrders }) =>
      countBy(roomStayOrders, (order) => order?.room?.roomTypeName),

    reservationHotelTotalPrice: (_, { bookingRequest }) =>
      bookingRequest.hotelTotalPrice
  },
  mutations: {
    RESET_BOOKING_REQUEST(state) {
      state.bookingRequest = new BookingRequest()
    },
    UPDATE_BOOKING_REQUEST(state, bookingRequest) {
      state.bookingRequest = createBookingRequest(
        state.bookingRequest,
        bookingRequest
      )
    },
    ADD_NEW_ROOM_STAY(state, { roomStay, roomStayIndex = null }) {
      if (!roomStayIndex) {
        state.bookingRequest.roomStays.push(new RoomStay(roomStay))
        return
      }
      state.bookingRequest.roomStays[roomStayIndex] = new RoomStay(roomStay)

      state.bookingRequest = createBookingRequest(state.bookingRequest)
    },
    UPDATE_ROOM_STAYS_TAXES(state, { roomStays }) {
      for (const roomStay of state.bookingRequest.roomStays) {
        const matchingRoom = roomStays.find(
          (room) => room.guestRoomId === roomStay.order.guestRoomId
        )
        if (matchingRoom) {
          roomStay.order.taxes = matchingRoom.taxes
          roomStay.order.services = matchingRoom.services
        }
      }
      state.bookingRequest = createBookingRequest(state.bookingRequest)
    },
    REMOVE_ROOM_STAY(state, roomStay) {
      const bookingRequest = state.bookingRequest
      bookingRequest.roomStays = without(bookingRequest.roomStays, roomStay)

      state.bookingRequest = createBookingRequest(bookingRequest)
    },
    REMOVE_LAST_ROOM_STAY_ORDER(state) {
      const roomStays = state.bookingRequest.roomStays
      roomStays[roomStays.length - 1].order = null

      state.bookingRequest = createBookingRequest(state.bookingRequest)
    },
    ADD_ORDER_TO_ROOM_STAY(state, { order, roomStayIndex }) {
      state.bookingRequest.roomStays[roomStayIndex].order = order

      state.bookingRequest = createBookingRequest(state.bookingRequest)
    },
    REMOVE_SERVICE_FROM_ROOM_STAY(state, { order, roomStayIndex, service }) {
      if (state.bookingRequest.roomStays[roomStayIndex]) {
        state.bookingRequest.roomStays[roomStayIndex].order = order
        state.bookingRequest = createBookingRequest(state.bookingRequest)
      }
    },
    COMPLETE_ROOM_STAY_BOOKING(state, roomStayIndex) {
      state.bookingRequest.roomStays[roomStayIndex].completed = true

      state.bookingRequest = createBookingRequest(state.bookingRequest)
    },
    COMPLETE_BOOKING(state, value) {
      state.bookingCompleted = value
    }
  }
}
