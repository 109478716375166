import mitt from 'mitt'

type EmitterEvents = {
  'refresh-session': undefined
  'payrails-client-init': boolean
  'juspay-client-init': boolean,
  'promoCodeSubmitted': string
}

const emitter = mitt<EmitterEvents>()

const MittPlugin = {
  install(Vue: any) {
    // For Vue 3, use `app.config.globalProperties`
    if (Vue.config?.globalProperties) {
      Vue.config.globalProperties.$emitter = emitter
    } else {
      // For Vue 2, use Vue's prototype
      Vue.prototype.$emitter = emitter
    }

    // Optionally provide the emitter for Composition API in Vue 3
    if (Vue.provide) {
      Vue.provide('emitter', emitter)
    }
  },
};

export { emitter, MittPlugin }
