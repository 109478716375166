import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import state from './state'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'

import RoutingModule from './modules/routing'
import SessionModule from './modules/session'
import bookingRequest from './modules/bookingRequest'
import { isLocalStorageAccessible, isDevMode } from '../system/helper'

import analyticsPlugin from './plugins/analytics'

let vuexLocal = {}
const storage = isLocalStorageAccessible()

if (storage) {
  vuexLocal = new VuexPersistence({
    key: 'SB4.vuex',
    storage: window.localStorage,
    modules: ['bookingRequest']
  })
}

Vue.use(Vuex)

const storePlugin = (store) => {
  store.watch(
    (_, getters) => getters.bookingRequest,
    (bookingRequest) => {
      Vue.cookie.set('bookingRequest', JSON.stringify(bookingRequest), {
        expires: process.env.SESSION_LIFETIME
      })
    }
  )
  store.subscribe((mutation) => {
    if (Vue.prototype.$hotelId) {
      switch (mutation.type) {
        case 'SET_CURRENCY':
          if (
            store.state.route.name !== 'ReservationModify' &&
            store.state.route.name !== 'package'
          ) {
            store.dispatch('getRoomsAvailabilities')
          }
          break
        case 'SET_LANGUAGE':
          store.dispatch('getDescriptiveInfo')
          break
      }
    }
  })
}

const plugins = [storePlugin]
plugins.push(analyticsPlugin)

if (vuexLocal) {
  plugins.push(vuexLocal.plugin)
}

const store = new Vuex.Store({
  state,
  mutations,
  getters,
  actions,
  modules: {
    RoutingModule,
    SessionModule,
    bookingRequest
  },
  plugins
})

export default store
export const useStore = () => store
