import Vue from 'vue'
import VueGtag from 'vue-gtag'
import VueFriendlyIframe from 'vue-friendly-iframe'
import VueResource from 'vue-resource'
import VueCookie from 'vue-cookie'
import VueLocalStorage from 'vue-localstorage'
import PortalVue from 'portal-vue'
import VueLazyload from 'vue-lazyload'
import VueIntersect from 'vue-intersect-directive'
import VCalendar from 'v-calendar'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import * as icons from '@/app/system/fontAwesome'
import { isDevMode } from '@/app/system/helper'
import EventBus from '@/app/system/plugins/EventBus'
import './router-hooks'
import vueRouter from '../router'

if (process.env.SENTRY_ENABLED === 'true') {
  import('./sentry-config')
}

library.add(icons)

Vue.component('fa-icon', FontAwesomeIcon)
Vue.use(VueGtag, { bootstrap: false }, vueRouter)
Vue.use(EventBus)
Vue.use(VueFriendlyIframe)
Vue.use(VueIntersect)
Vue.use(VueResource)
Vue.use(VueCookie)
Vue.use(PortalVue)
Vue.use(VueLazyload)
Vue.use(VCalendar, {
  componentPrefix: 'vc'
})
Vue.use(VueLocalStorage, {
  name: 'ls',
  namespace: 'SB4',
  bind: true
})

Vue.config.productionTip = false
