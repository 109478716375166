import { useJuspay } from './useJuspay'
import { usePayrails } from './usePayrails'
import { unref } from 'vue'

export function usePaymentIntegration() {
  const { processJuspayPayment, loadJuspayScript } = useJuspay()
  const { processPayrailsPayment } = usePayrails()

  const initializePaymentProvider = async (provider: string) => {
    if (provider === 'juspay') {
      await loadJuspayScript()
    }
  }

  const initPayment = async (paymentData: InitiatePaymentResponseJuspay | InitiatePaymentResponsePayrails) => {
    if (!paymentData || paymentData.status !== 'success') {
      console.error('Invalid payment data or status:', paymentData)
      return
    }

    try {
      if (paymentData.paymentProvider === 'JUSPAY') {
        await processJuspayPayment(unref(paymentData))
      } else if (paymentData.paymentProvider === 'PAYRAILS') {
        if (!paymentData.data?.data || !paymentData.data?.version) {
          throw new Error('Invalid Payrails payment data structure')
        }
        await processPayrailsPayment(paymentData)
      } else {
        throw new Error(`Unsupported payment provider: ${paymentData.paymentProvider}`)
      }
    } catch (error) {
      console.error(`Failed to process ${paymentData.paymentProvider} payment:`, error)
      throw error // Re-throw to allow caller to handle the error
    }
  }

  return {
    initPayment,
    initializePaymentProvider
  }
}
