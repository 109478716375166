import Vue from 'vue'
import VueGtm from '@gtm-support/vue2-gtm'
import { setOptions, bootstrap } from 'vue-gtag'
import { isEmpty, wrap } from 'lodash'
import vueRouter from '../../router'
import {
  isDevMode,
  convertPriceStringToFixedPrecision,
  parseJson,
  log,
  getGA4ClientId,
  getGA4FirstSessionTimestamp
} from '../../system/helper'

import store from '../../store'

class AnalyticsPlugin {
  constructor() {
    this.trackingPluginsEnabled = false
    this.GTM_CONTAINERS = [process.env.HS_GTM_CONTAINER_ID]
    this.GTAG_IDs = []
    this.EVENTS = {
      SET_SEARCH_PARAMS: {
        gtm: {
          eventName: 'search',
          lookupMethod: this.lookupSetSearchParams
        }
      },
      COMPLETE_BOOKING: {
        gtm: {
          eventName: 'purchase',
          lookupMethod: this.lookupCompleteBooking
        }
      },
      ADD_ORDER_TO_ROOM_STAY: {
        gtm: {
          eventName: 'add_to_cart',
          lookupMethod: this.lookupAddOrderToRoomStay
        }
      },
      REMOVE_SERVICE_FROM_ROOM_STAY: {
        gtm: {
          eventName: 'remove_from_cart',
          lookupMethod: this.lookupRemoveServiceFromRoomStay
        }
      },
      REMOVE_LAST_ROOM_STAY_ORDER: {
        gtm: {
          eventName: 'remove_from_cart',
          lookupMethod: this.lookupRemoveLastRoomStayOrder
        }
      }
    }
  }

  enableTrackingPlugins(trackers) {
    if (!isEmpty(trackers)) {
      const { GoogleTagManager, GoogleAnalytics, GoogleGlobalSiteTag } =
        trackers

      if (GoogleGlobalSiteTag?.tagId && GoogleGlobalSiteTag?.routeId) {
        this.GTAG_IDs.push({
          id: GoogleGlobalSiteTag.tagId,
          params: { routeId: GoogleGlobalSiteTag.routeId }
        })
      }

      if (GoogleTagManager?.accountId) {
        this.GTM_CONTAINERS.push(GoogleTagManager.accountId)
      }

      if (GoogleAnalytics?.trackingCodev4) {
        this.GTAG_IDs.push({
          id: GoogleAnalytics.trackingCodev4
        })
      }
    }

    Vue.use(VueGtm, {
      id: [...new Set(this.GTM_CONTAINERS)],
      debug: true,
      enabled: true,
      vueRouter,
      source: `${process.env.HS_GTM_SS_URL}/gtm.js`
    })

    setOptions({
      includes: this.GTAG_IDs,
      config: {
        server_container_url: process.env.HS_GTM_SS_URL
      }
    })

    bootstrap().then(() => {
      this.trackingPluginsEnabled = true

      store.commit('SET_ANALYTICS_PARAMETER', {
        platform: 'GA4',
        key: 'clientId',
        value: getGA4ClientId()
      })

      store.commit('SET_ANALYTICS_PARAMETER', {
        platform: 'GA4',
        key: 'sessionId',
        value: getGA4FirstSessionTimestamp()
      })
    })
  }

  trackGA4Event(eventName, payload) {
    Vue.$gtag.event(eventName, payload)
  }

  trackGTMEvent(eventName, payload) {
    Vue.gtm?.trackEvent({
      event: eventName,
      ...payload,
      send_to: [...new Set(this.GTM_CONTAINERS)]
    })
  }

  // Lookup methods
  lookupSearchParams(state) {
    const {
      promoCode,
      sessionId,
      currencyCode,
      start,
      end,
      nbAdults,
      nbChildren,
      nbInfants,
      roomStayIndex
    } = state.searchParams
    return {
      hotelId: state.route.params.hotelId,
      roomIndex: roomStayIndex,
      arrival: start,
      departure: end,
      numAdults: nbAdults,
      numChildren: nbChildren,
      numInfants: nbInfants,
      promoCode
    }
  }

  lookupCompleteBooking() {
    return store.getters.ga4Purchase
  }

  lookupAddOrderToRoomStay({ currentRoomStay, servicesInfo }, payload) {
    const {
      order: {
        isPackage,
        hotelPackageName = '',
        hotelPackageId,
        currency,
        totalPrice,
        totalDiscount,
        productId,
        room = {},
        services = []
      }
    } = payload

    const findServiceDetails = (serviceId) =>
      servicesInfo.find((service) => service.serviceId === serviceId) || {}

    const mainItem = {
      ...store.getters.ga4HotelAttributes,
      item_category: isPackage ? 'package' : 'product',
      item_category2: isPackage ? hotelPackageId : productId,
      item_category3: hotelPackageName,
      coupon: currentRoomStay.promotionCode,
      item_variant: room.roomTypeName,
      bookingChannelId: store.state.channelId
    }

    const servicesItems = services.map((service) => {
      const { serviceId, quantity = 1, totalPrice, totalDiscount } = service
      const serviceDetails = findServiceDetails(serviceId)

      return {
        item_id: serviceId,
        item_name: serviceDetails.name || '',
        price: convertPriceStringToFixedPrecision(totalPrice - totalDiscount),
        discount: convertPriceStringToFixedPrecision(totalDiscount),
        quantity,
        bookingChannelId: store.state.channelId
      }
    })

    return {
      ecommerce: {
        currency,
        value: convertPriceStringToFixedPrecision(totalPrice - totalDiscount),
        bookingChannelId: store.state.channelId,
        items: [mainItem, ...servicesItems]
      }
    }
  }

  lookupRemoveServiceFromRoomStay({ currentRoomStay, servicesInfo }, payload) {
    const {
      order: { currency },
      service
    } = payload

    const serviceInfo = service || {}

    return {
      ecommerce: {
        currency,
        value: convertPriceStringToFixedPrecision(serviceInfo.totalPrice || 0),
        bookingChannelId: store.state.channelId,
        items: [
          {
            item_id: serviceInfo.serviceId,
            item_name: serviceInfo.name || '',
            price: convertPriceStringToFixedPrecision(
              serviceInfo.totalPrice || 0
            ),
            discount: convertPriceStringToFixedPrecision(
              serviceInfo.totalDiscount || 0
            ),
            quantity: serviceInfo.quantity || 1,
            bookingChannelId: store.state.channelId
          }
        ]
      }
    }
  }

  lookupRemoveLastRoomStayOrder(state, payload) {
    const { bookingRequest } = state || {}
    if (!bookingRequest || !bookingRequest.roomStays) {
      return {
        ecommerce: {
          currency: 'EUR',
          value: 0,
          items: []
        }
      }
    }
    
    const roomStays = bookingRequest.roomStays
    const lastRoomStayIndex = roomStays.length - 1

    // If there are no room stays or the last one doesn't have an order, return empty payload
    if (lastRoomStayIndex < 0 || !roomStays[lastRoomStayIndex].order) {
      return {
        ecommerce: {
          currency: bookingRequest.currencyCode || 'EUR',
          value: 0,
          bookingChannelId: state.channelId,
          items: []
        }
      }
    }

    const lastOrder = roomStays[lastRoomStayIndex].order
    const {
      isPackage,
      hotelPackageName = '',
      hotelPackageId,
      currency,
      totalPrice,
      totalDiscount,
      productId,
      room = {},
      services = []
    } = lastOrder

    const servicesInfo = state.servicesInfo || []
    const findServiceDetails = (serviceId) =>
      servicesInfo.find((service) => service.serviceId === serviceId) || {}

    const mainItem = {
      ...store.getters.ga4HotelAttributes,
      item_category: isPackage ? 'package' : 'product',
      item_category2: isPackage ? hotelPackageId : productId,
      item_category3: hotelPackageName,
      coupon: roomStays[lastRoomStayIndex].promotionCode,
      item_variant: room.roomTypeName,
      bookingChannelId: state.channelId
    }

    const servicesItems = services.map((service) => {
      const { serviceId, quantity = 1, totalPrice, totalDiscount } = service
      const serviceDetails = findServiceDetails(serviceId)

      return {
        item_id: serviceId,
        item_name: serviceDetails.name || '',
        price: convertPriceStringToFixedPrecision(totalPrice - totalDiscount),
        discount: convertPriceStringToFixedPrecision(totalDiscount),
        quantity,
        bookingChannelId: state.channelId
      }
    })

    return {
      ecommerce: {
        currency,
        value: convertPriceStringToFixedPrecision(totalPrice - totalDiscount),
        bookingChannelId: state.channelId,
        items: [mainItem, ...servicesItems]
      }
    }
  }

  lookupSetSearchParams(state) {
    const {
      promoCode,
      sessionId,
      currencyCode,
      start,
      end,
      nbAdults,
      nbChildren,
      nbInfants
    } = state.searchParams
    return {
      search_term: `Availability search: ${start} - ${end} for ${nbAdults} adults, ${nbChildren} children, and ${nbInfants} infants.${
        promoCode ? ` Promo code ${promoCode} is applicable.` : ''
      } Session ID: ${sessionId}.`,
      bookingChannelId: state.channelId
    }
  }

  trackEvent(eventType, eventConfig, state, payload) {
    try {
      const { eventName, lookupMethod } = eventConfig
      if (!lookupMethod) return
      
      const eventPayload = lookupMethod.call(this, state, payload)
      if (!eventPayload) return

      if (eventType === 'ga4') {
        this.trackGA4Event(eventName, eventPayload)
      } else if (eventType === 'gtm') {
        this.trackGTMEvent(eventName, eventPayload)
      }
    } catch (error) {
      console.error(`Error in trackEvent for ${eventType}:`, error)
    }
  }

  handleMutation(mutation, state) {
    if (mutation.type === 'SET_CONFIG' && !this.trackingPluginsEnabled) {
      const { trackers } = mutation.payload || {}
      try {
        if (trackers) {
          const trackingConfig = parseJson(trackers)
          log.info('trackingConfig', trackingConfig)
          this.enableTrackingPlugins(trackingConfig)
        }
      } catch (e) {
        console.error(`Error parsing trackers config: ${e}`)
      }
    }

    try {
      const mutationName = mutation.type.split('/')[0]
      const eventConfigs = this.EVENTS[mutationName]

      if (eventConfigs) {
        Object.entries(eventConfigs).forEach(([eventType, eventConfig]) => {
          if (eventConfig && typeof eventConfig.lookupMethod === 'function') {
            this.trackEvent(eventType, eventConfig, state, mutation.payload)
          }
        })
      }
    } catch (e) {
      console.error(`Error in trackEvent: ${e}`)
      // Log error instead of throwing to prevent app from crashing
    }
  }
}

export default (store) => {
  const analyticsPlugin = new AnalyticsPlugin()
  store.subscribe((mutation, state) =>
    analyticsPlugin.handleMutation(mutation, state)
  )
}
