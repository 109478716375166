import { ref, shallowRef } from 'vue'
import { loadScript } from 'vue-plugin-load-script'
import { isDevMode } from '@/app/system/helper'
import store from '@/app/store'
import { emitter } from '@/app/system/plugins/mitt'

// Global instance that persists across components
const globalJuspayInstance = shallowRef<any>(null)

export function useJuspay() {

  const initializeJuspaySDK = () => {
    const { merchantId, clientId } = store.state.paymentParameters
    try {
      if (window?.HyperServices) {
        globalJuspayInstance.value = new window.HyperServices()

        globalJuspayInstance.value.initiate({
          requestId: crypto.randomUUID(),
          service: "in.juspay.hyperpay",
          payload: {
            action: "initiate",
            hyperSDKDiv: "juspayContainer",
            integrationType: "iframe",
            //environment: isDevMode() ? "sandbox" : "production",
            environment: "production",
            merchantId,
            clientId
          }
        }, handleJuspayCallback)
      } else {
        console.error('HyperServices not loaded')
      }
    } catch (error) {
      console.error('Error initializing Juspay SDK:', error)
    }
  }


  const processJuspayPayment = async (paymentData: InitiatePaymentResponseJuspay) => {
    try {
      if (!paymentData?.data?.sdk_payload || paymentData.paymentProvider !== 'JUSPAY') {
        throw new Error('Invalid Juspay payment data structure')
      }

      if (globalJuspayInstance.value?.isInitialised()) {
        globalJuspayInstance.value.process(paymentData.data.sdk_payload)
        emitter.emit('juspay-client-init', true)
      } else {
        console.error('HyperServices not initialized')
        throw new Error('Payment service not initialized')
      }
    } catch (error) {
      console.error('Error processing payment:', error)
      throw error
    }
  }

  const handleJuspayCallback = (eventData: any) => {
    try {
      if (eventData) {
        const eventJSON = typeof eventData === 'string' ? JSON.parse(eventData) : eventData
        const event = eventJSON.event

        switch (event) {
          case 'initiate_result':
            console.log('Initiate Result:', eventJSON)
            break
          case 'process_result':
            console.log('Process Result:', eventJSON)
            break
          case 'user_event':
            console.log('User Event:', eventJSON)
            break
          default:
            console.log('Unhandled event', event, 'Event data', eventData)
        }
      }
    } catch (error) {
      console.error('Error in hyperSDK response', error)
    }
  }

  const loadJuspayScript = async () => {
    try {
      await loadScript('https://payments.juspay.in/payment-page/HyperServices.js')
      initializeJuspaySDK()


    } catch (e) {
      console.error('Juspay script could not be loaded:', e)
    }
  }

  return {
    juspayInstance: globalJuspayInstance,
    loadJuspayScript,
    processJuspayPayment
  }
}
