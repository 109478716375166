// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import '@/app/static/css/main.scss'
// manual polyfill import
import objectFitImages from 'object-fit-images'

import Vue, { provide } from 'vue'
import dayjs from 'dayjs'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { isEmpty } from 'lodash'

import App from './App'
import router from './app/router'
import store from './app/store'
import {
  formatDate,
  isAccommodationDateValid,
  isDevMode,
  isMockEnabled,
  resizeIframe,
  toggleModal,
  replaceQueryParameter,
  isMaintenanceMode
} from './app/system/helper'
import mainMixin from './app/system/mixins/mainMixin'
import localStorage from './app/system/mixins/localStorage'
import './app/vue-config/vue-setup'
import { createBookingRequest } from './app/store/modules/bookingRequest'
import { CHANNEL_ID_TRIP_ADVISOR } from './app/system/constants/channels'
import { i18n, loadLanguage } from './app/vue-config/i18n'
import vuetify from '@/app/vue-config/vuetify'
import { createHead } from '@unhead/vue'
import { UnheadPlugin } from '@unhead/vue/vue2'
import SentryClient from './app/system/SentryClient'
import { MittPlugin, emitter } from '@/app/system/plugins/mitt'

const head = createHead()
if (isDevMode() && isMockEnabled()) {
  const { worker } = require('./mocks/browser')
  worker.start({
    onUnhandledRequest: 'bypass'
  })
}

// object-fit polyfill for IE11
objectFitImages()

Vue.mixin(mainMixin)
Vue.use(UnheadPlugin)
Vue.use(MittPlugin)
// app.use(MittPlugin); // for Vue 3

new Vue({
  unhead: head,
  vuetify,
  i18n,
  el: '#app',
  router,
  store,

  components: { App },

  mixins: [localStorage],

  setup() {
    provide('emitter', emitter)
  },
  computed: {
    ...mapGetters([
      'availabilitiesLoaded',
      'configLoaded',
      'currency',
      'currentRoom',
      'customization',
      'descriptiveInfoLoaded',
      'device',
      'filteredRooms',
      'guestsBounds',
      'hotelName',
      'language'
    ])
  },
  watch: {
    '$i18n.locale'() {
      if (i18n.lazyloaded) {
        this.$store.dispatch('getCurrenciesTranslations')
      }
    },
    '$store.state.language'(val, prev) {
      if (val === prev) {
        return
      }

      loadLanguage(val)
      this.lsSave('language', val)

      if (this.$route.query.lang && this.$route.query.lang !== val) {
        this.$router.replace({ query: this.setQueryParam('lang', val) })
      }
    },
    currency(val) {
      if (val !== undefined) {
        this.lsSave('currency', val) // save currency in local storage
      }
      // if the currency is set in the query string, we have to replace it
      if (this.$route.query.currency && this.$route.query.currency !== val) {
        this.$router.replace({ query: this.setQueryParam('currency', val) })
      }
    },
    configLoaded(val) {
      if (val) {
        this.$root.refreshSession()
      }
    },
    availabilitiesLoaded(val) {
      if (val) {
        this.$nextTick(() => {
          this.RESET_ROOM_FILTERS()
        })
      }
    }
  },
  async created() {
    if (this.$hotelId) {
      await this.setPreferredLanguage()
      this.getConfigCustomization()

      if (isMaintenanceMode()) {
        return
      }

      this.setConfig().then(() => {
        this.setCurrency()
      })
      this.setCurrencies()
      this.getPromotionInfo()
    }

    resizeIframe()

    let tripAdvisorRefId = this.$cookie.get('tripadvisor_refid')
    if (tripAdvisorRefId) {
      store.commit('SET_CHANNEL_ID', CHANNEL_ID_TRIP_ADVISOR)
      store.commit('SET_CHANNEL_PARAMETERS', {
        [CHANNEL_ID_TRIP_ADVISOR]: { refid: tripAdvisorRefId }
      })
    }

    this.$root.toggleModal = function (val, scrollSelector, delay) {
      toggleModal(val, scrollSelector, delay)
    }

    this.$root.refreshSession = function () {
      const query = this.$route?.query
      const sessionId = this.$cookie.get('sessionId')
      const bookingRequestSaved = JSON.parse(this.$cookie.get('bookingRequest'))
      let bookingRequest,
        checkIn = dayjs(),
        checkOut,
        promoCodeInput = '',
        nbAdults,
        nbChildren,
        nbInfants
      if (!isEmpty(query)) {
        const { refid } = query
        if (refid) {
          this.$cookie.get('tripadvisor_refid') === refid
            ? ''
            : this.$cookie.set('tripadvisor_refid', refid, { expires: '30D' })
        }
        ;({
          checkIn = dayjs(),
          checkOut,
          promoCode: promoCodeInput,
          nbAdults,
          nbChildren,
          nbInfants
        } = query)
      } else if (
        sessionId &&
        bookingRequestSaved &&
        sessionId === bookingRequestSaved.sessionId
      ) {
        bookingRequest = createBookingRequest(bookingRequestSaved)

        let savedCheckIn = bookingRequest.checkIn
        if (!isAccommodationDateValid(savedCheckIn)) {
          savedCheckIn = bookingRequest.checkIn = formatDate(
            dayjs().startOf('day')
          )
        }
        if (!isAccommodationDateValid(bookingRequest.checkOut, savedCheckIn)) {
          bookingRequest.checkOut = formatDate(
            dayjs(savedCheckIn).add(1, 'days')
          )
        }
      }

      if (!bookingRequest) {
        checkOut = checkOut ? checkOut : dayjs(checkIn).add(1, 'days')
        bookingRequest = createBookingRequest({
          checkIn: formatDate(checkIn),
          checkOut: formatDate(checkOut),
          promoCodeInput
        })

        // Set number of guests to the default "roomStay" if we get 'nbAdults' from the query string,
        const guestsBounds = this.guestsBounds

        function updateGuests(guestType, value) {
          const { min, max } = guestsBounds[guestType]
          const nbGuests = Math.min(Math.max(parseInt(value), min), max)

          bookingRequest.roomStays[0][guestType] = nbGuests
          replaceQueryParameter(guestMap[guestType], nbGuests.toString())
        }

        const guestMap = {
          adults: 'nbAdults',
          children: 'nbChildren',
          infants: 'nbInfants'
        }

        if (nbAdults) {
          updateGuests('adults', nbAdults)
        }

        if (nbChildren) {
          updateGuests('children', nbChildren)
        }

        if (nbInfants) {
          updateGuests('infants', nbInfants)
        }
      }

      // Refresh "session ID" lifetime in the cookie.
      this.$cookie.set('sessionId', bookingRequest.sessionId, {
        expires: process.env.SESSION_LIFETIME
      })

      this.UPDATE_BOOKING_REQUEST(bookingRequest)
      // reinit parameters
      this.SET_ALLOW_THANKS(false)
      this.SET_ALLOW_CANCELLATION_CONFIRMATION(false)

      const order = this.$store.getters.currentRoomStayGuests.order
      if (order) {
        this.ADD_ORDER_TO_ROOM_STAY({
          order: {
            ...order,
            totalPrice: order.hotelTotalPrice,
            totalDiscount: order.hotelTotalDiscount
          },
          roomStayIndex: this.currentRoom
        })
        this.UPDATE_CURRENT_ROOM_STAY({
          ...this.$store.getters.currentRoomStayGuests.order
        })
      } else {
        this.submitSearchInfo()
      }
    }
  },
  mounted() {
    if (this.$hashUrl) {
      setTimeout(() => {
        SentryClient.captureExceptionWithScope(
          'URL hash property usage',
          {
            extraKey: 'OTA failure with a hotel ID',
            extraData: { hotelId: Vue.prototype.$hotelId },
            tags: {
              type: 'OTA_failure',
              referrer: document.referrer
                ? new URL(document.referrer).hostname
                : ''
            }
          },
          'critical'
        )
      }, 2000)
    }

    document.addEventListener('mousewheel', function () {
      if (document.activeElement && document.activeElement.type === 'number') {
        document.activeElement.blur()
      }
    })
    this.$bus.$on('resetSearchParams', () => {
      const today = dayjs()
      const tomorrow = dayjs().add(1, 'days')
      const bookingRequestNew = {
        checkIn: formatDate(today),
        checkOut: formatDate(tomorrow)
      }
      this.RESET_BOOKING_REQUEST()
      this.UPDATE_BOOKING_REQUEST(bookingRequestNew)
      this.submitSearchInfo()
      this.$bus.$emit('refreshSearchFields')
    })
  },
  methods: {
    ...mapMutations([
      'UPDATE_BOOKING_REQUEST',
      'RESET_BOOKING_REQUEST',
      'RESET_ROOM_FILTERS',
      'SET_ALLOW_THANKS',
      'SET_ALLOW_CANCELLATION_CONFIRMATION'
    ]),
    ...mapActions([
      'getPromotionInfo',
      'getConfigCustomization',
      'setConfig',
      'setPreferredLanguage',
      'setCurrencies'
    ]),
    setQueryParam(param, value) {
      const query = Object.assign({}, this.$route.query)
      query[param] = value
      return query
    },
    setCurrency() {
      const sessionCurrency =
        this.lsGet('currency') || this.$store.state.hotelCurrency
      const { currency = sessionCurrency } = this.$route.query

      if (this.currency !== currency) {
        this.$store.commit('SET_CURRENCY', currency)
      }
    }
  },
  template: '<App/>'
})
